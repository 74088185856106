.subcontractorCell {
  display: flex;
  flex-direction: column;
}

.permissionSelect {
  width: 100%;
  max-width: 50%;
}

.bottomSpace {
  padding-bottom: .5em;
}
