.formTabs {
  margin: 0;
  border-bottom: 1px solid lightgrey;
}

.permissionsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.permissionsGroup {
  margin: 0 0 1em;
}

.changedPermissionsList {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
