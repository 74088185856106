.smsLogTable {
  height: 40em;
  overflow: scroll;

  thead th {
    background-color: white;
    box-shadow: 0 -1px grey, 0 1px grey;
    top: 0;
    position: sticky;
  }
}

.settingsTable {
  display: grid;
  grid-template-columns: max-content 1fr;
}

.settingsHeader {
  display: flex;
  padding-bottom: .5em;
  align-items: center;

  & h5 {
    margin: 0 .5em 0 0;
    text-align: justify;
    vertical-align: middle;
  }
}

.languageSelect {
  width: 100%;
  max-width: 50%;
}

.accountDetailsTabs {
  margin: 0;
  border-bottom: 1px solid lightgrey;
}
